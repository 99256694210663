/* global rp$, window, grecaptcha */

var THE_SCRIPT = function () {

	const $onReady = require('public/js/lib/on-ready');

	const loginFormId            = '#login-user-form';
	const loginSubmitButtonClass = '.login-submit-button';

	window.getCaptchaToken = function(token) {
		loginUserFormSubmit();
	}

	function loginUserFormSubmit() {
		rp$(loginFormId).submit();
	}

	$onReady({
		rp$,
		window,
		label: 'login_user_form',
		fn: function () {
			rp$(loginSubmitButtonClass).on('click', function (evt) {
				evt.preventDefault();
				if(rp$(this).hasClass('disabled')) {
					return;
				}

				if(typeof grecaptcha === 'object') {
					var captchaIndex = -1;
					rp$('.g-recaptcha').each(function(index) {
						if(rp$(this)[0].id === 'login-user-form-captcha') {
							captchaIndex = index;
						}
					});
					grecaptcha.execute(captchaIndex);
				} else {
					loginUserFormSubmit();
				}
			});
		},
	});
};

try {
    // Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
